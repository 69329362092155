import { z } from "zod";

import { prepareConfig } from "@projectluna/lib/zod/env";

import { envBool, envToStrList } from "./helpers";

const minute = 60;
const tenMinutes = minute * 10;
const hour = 3600;
const day = hour * 24;
const thirtyDays = day * 30;

const serverConfigSchema = z
  .object({
    FACEBOOK_API_VERSION: z.string().default("v19.0"),
    FACEBOOK_PAGE_ID: z.string(),

    FACEBOOK_MESSENGER_APP_ID: z.string(),
    FACEBOOK_MESSENGER_APP_SECRET: z.string(),
    FACEBOOK_MESSENGER_APP_ACCESS_TOKEN: z.string(),
    FACEBOOK_MESSENGER_RECIPIENTS_ID: z.array(z.string()),

    FACEBOOK_INSTAGRAM_POSTS_FEED_APP_ACCESS_TOKENS: z.array(z.string()),

    REVALIDATE_API_TOKEN: z.string(),

    CRON_SECRET: z.string(),

    VERCEL_ANALYTICS_ENABLED: envBool.optional().default("false"),
    VERCEL_INSIGHTS_ENABLED: envBool.optional().default("false"),

    MAINTENANCE_MODE_FORCE: envBool.default("false"),
    MAINTENANCE_WHITELIST_IPS: z.array(z.string()),

    CACHE_TTL_DEFAULT: z.coerce.number().default(hour),
    CACHE_TTL_DEFAULT_API: z.coerce.number().default(tenMinutes),
    CACHE_TTL_INSTAGRAM_API: z.coerce.number().default(day),
    CACHE_TTL_HOME: z.coerce.number().default(day),
    CACHE_TTL_PRODUCT_DETAIL_PAGE: z.coerce.number().default(day),
    CACHE_TTL_PRODUCT_BREADCRUMBS: z.coerce.number().default(day),
    CACHE_TTL_CATEGORY_CARDS: z.coerce.number().default(day),
    CACHE_TTL_LISTINGS: z.coerce.number().default(day),
    CACHE_TTL_LISTINGS_FILTERS: z.coerce.number().default(day),
    CACHE_TTL_GA_ANALYTICS: z.coerce.number().default(day),
    CACHE_TTL_POPULAR_PRODUCTS: z.coerce.number().default(day),
    CACHE_TTL_PAGES: z.coerce.number().default(thirtyDays),
    CACHE_TTL_VALIDATION_RULES: z.coerce.number().default(thirtyDays),
    CACHE_TTL_CHECKOUT: z.coerce.number().default(tenMinutes),
    CACHE_TTL_ORDER: z.coerce.number().default(hour),
    CACHE_TTL_CONFIG: z.coerce.number().default(thirtyDays),
    CACHE_TTL_PROMO_BANNER: z.coerce.number().default(day),

    COOKIE_MAX_AGE_CHECKOUT: z.coerce.number().default(thirtyDays),

    // Requires manage orders, manage products
    SALEOR_APP_TOKEN: z.string(),

    TOP_SELLING_VARIANTS_PERIOD: z
      .enum(["THIS_MONTH", "TODAY"])
      .default("THIS_MONTH"),

    SSR_FETCH_LIMIT: z.coerce.number(),
    SSR_FETCH_BATCH: z.coerce.number(),

    STRIPE_SECRET_KEY: z.string(),

    GOOGLE_CLOUD_GA_CLIENT_EMAIL: z.string(),
    GOOGLE_CLOUD_GA_PRIVATE_KEY: z
      .string()
      .transform(key => key.split(String.raw`\n`).join("\n")),

    LOGGER_LEVEL: z
      .enum(["crictical", "error", "warn", "info", "debug", "notset"])
      .default("info"),
    LOGGER_SCRUB_KEYS: z.array(z.string()).default(["token", "apiKey"]),

    ROBOTS_INDEX_ALLOW: envBool.default("true"),

    BASIC_AUTH_ENABLED: envBool.default("false"),
    BASIC_AUTH_USER: z.string().optional(),
    BASIC_AUTH_PASSWORD: z.string().optional(),
  })
  .superRefine((input, ctx) => {
    if (input.BASIC_AUTH_ENABLED) {
      ["BASIC_AUTH_USER", "BASIC_AUTH_PASSWORD"].forEach(field => {
        if (!input[field as keyof typeof input]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `must be set with BASIC_AUTH_ENABLED`,
            path: [field],
            fatal: true,
          });
        }
      });
    }
  });

export const SERVER_CONFIG = prepareConfig({
  name: "SERVER_CONFIG",
  schema: serverConfigSchema,
  serverOnly: true,
  input: {
    MAINTENANCE_WHITELIST_IPS: envToStrList(
      process.env.MAINTENANCE_WHITELIST_IPS
    ),
    FACEBOOK_INSTAGRAM_POSTS_FEED_APP_ACCESS_TOKENS: envToStrList(
      process.env.FACEBOOK_INSTAGRAM_POSTS_FEED_APP_ACCESS_TOKENS
    ),
    FACEBOOK_MESSENGER_RECIPIENTS_ID: envToStrList(
      process.env.FACEBOOK_MESSENGER_RECIPIENTS_ID
    ),
  },
});
