// @ts-nocheck
// prettier-ignore
/* eslint-disable */
/* @typescript-eslint/no-unused-vars */
import type * as Types from '@projectluna/codegen/schema';

import type { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type AddressValidationRulesQuery_addressValidationRules_AddressValidationData_countryAreaChoices_ChoiceValue = { raw: string | null, verbose: string | null };

export type AddressValidationRulesQuery_addressValidationRules_AddressValidationData_cityChoices_ChoiceValue = { raw: string | null, verbose: string | null };

export type AddressValidationRulesQuery_addressValidationRules_AddressValidationData = { requiredFields: Array<string>, countryAreaType: string, postalCodeType: string, cityType: string, postalCodeExamples: Array<string>, postalCodeMatchers: Array<string>, countryAreaChoices: Array<AddressValidationRulesQuery_addressValidationRules_AddressValidationData_countryAreaChoices_ChoiceValue>, cityChoices: Array<AddressValidationRulesQuery_addressValidationRules_AddressValidationData_cityChoices_ChoiceValue> };

export type AddressValidationRulesQuery_Query = { addressValidationRules: AddressValidationRulesQuery_addressValidationRules_AddressValidationData | null };


export type AddressValidationRulesQueryVariables = Types.Exact<{
  countryCode: Types.CountryCode;
}>;


export type AddressValidationRulesQuery = AddressValidationRulesQuery_Query;

export type CategoriesPathQuery_categories_CategoryCountableConnection_pageInfo_PageInfo = { hasNextPage: boolean, hasPreviousPage: boolean, endCursor: string | null, startCursor: string | null };

export type CategoriesPathQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category = { slug: string };

export type CategoriesPathQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge = { node: CategoriesPathQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category };

export type CategoriesPathQuery_categories_CategoryCountableConnection = { pageInfo: CategoriesPathQuery_categories_CategoryCountableConnection_pageInfo_PageInfo, edges: Array<CategoriesPathQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge> };

export type CategoriesPathQuery_Query = { categories: CategoriesPathQuery_categories_CategoryCountableConnection | null };


export type CategoriesPathQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.CategoryFilterInput>;
}>;


export type CategoriesPathQuery = CategoriesPathQuery_Query;

export type CategoryDetailsQuery_category_Category_thumbnail_Image = { alt: string | null, url: string };

export type CategoryDetailsQuery_category_Category_ogThumbnail_Image = { alt: string | null, url: string };

export type CategoryDetailsQuery_category_Category_translation_CategoryTranslation = { name: string | null, description: string | null };

export type CategoryDetailsQuery_category_Category = { id: string, name: string, slug: string, description: string | null, thumbnail: CategoryDetailsQuery_category_Category_thumbnail_Image | null, ogThumbnail: CategoryDetailsQuery_category_Category_ogThumbnail_Image | null, translation: CategoryDetailsQuery_category_Category_translation_CategoryTranslation | null };

export type CategoryDetailsQuery_Query = { category: CategoryDetailsQuery_category_Category | null };


export type CategoryDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
  ogThumbnailSize: Types.Scalars['Int']['input'];
  thumbnailFormat: Types.ThumbnailFormatEnum;
}>;


export type CategoryDetailsQuery = CategoryDetailsQuery_Query;

export type CategoryCategoriesQueries_category_Category_translation_CategoryTranslation = { name: string | null };

export type CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category_translation_CategoryTranslation = { name: string | null };

export type CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category = { slug: string, name: string, translation: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category_translation_CategoryTranslation | null };

export type CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category = { slug: string, name: string, parent: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category | null, translation: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category_translation_CategoryTranslation | null };

export type CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category = { slug: string, name: string, parent: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category | null, translation: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category_translation_CategoryTranslation | null };

export type CategoryCategoriesQueries_category_Category_parent_Category_parent_Category = { slug: string, name: string, parent: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category | null, translation: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category_translation_CategoryTranslation | null };

export type CategoryCategoriesQueries_category_Category_parent_Category = { slug: string, name: string, parent: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category | null, translation: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category_translation_CategoryTranslation | null };

export type CategoryCategoriesQueries_category_Category = { slug: string, name: string, translation: CategoryCategoriesQueries_category_Category_translation_CategoryTranslation | null, parent: CategoryCategoriesQueries_category_Category_parent_Category | null };

export type CategoryCategoriesQueries_Query = { category: CategoryCategoriesQueries_category_Category | null };


export type CategoryCategoriesQueriesVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  languageCode: Types.LanguageCodeEnum;
}>;


export type CategoryCategoriesQueries = CategoryCategoriesQueries_Query;

export type CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money = { amount: number, currency: string };

export type CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money = { amount: number, currency: string };

export type CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_totalPrice_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_undiscountedTotalPrice_Money = { amount: number, currency: string };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_unitPrice_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_media_ProductMedia = { alt: string, type: Types.ProductMediaType, thumbnail: string };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_translation_ProductVariantTranslation = { name: string };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product_thumbnail_Image = { alt: string | null, thumbnail: string };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product_translation_ProductTranslation = { name: string | null };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product_category_Category = { name: string };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product_pricing_ProductPricingInfo = { displayGrossPrices: boolean };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product = { id: string, slug: string, name: string, thumbnail: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product_thumbnail_Image | null, translation: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product_translation_ProductTranslation | null, category: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product_category_Category | null, pricing: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product_pricing_ProductPricingInfo | null };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant = { id: string, name: string, quantityAvailable: number | null, quantityLimitPerCustomer: number | null, media: Array<CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_media_ProductMedia> | null, translation: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_translation_ProductVariantTranslation | null, product: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant_product_Product };

export type CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine = { id: string, quantity: number, totalPrice: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_totalPrice_TaxedMoney, undiscountedTotalPrice: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_undiscountedTotalPrice_Money, unitPrice: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_unitPrice_TaxedMoney, variant: CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine_variant_ProductVariant };

export type CheckoutBaseQuery_checkout_Checkout_channel_Channel = { slug: string };

export type CheckoutBaseQuery_checkout_Checkout_user_User = { id: string, email: string };

export type CheckoutBaseQuery_checkout_Checkout = { id: string, quantity: number, email: string | null, displayGrossPrices: boolean, totalPrice: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney, lines: Array<CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine>, channel: CheckoutBaseQuery_checkout_Checkout_channel_Channel, user: CheckoutBaseQuery_checkout_Checkout_user_User | null };

export type CheckoutBaseQuery_Query = { checkout: CheckoutBaseQuery_checkout_Checkout | null };


export type CheckoutBaseQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  countryCode: Types.CountryCode;
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
  thumbnailFormat: Types.ThumbnailFormatEnum;
}>;


export type CheckoutBaseQuery = CheckoutBaseQuery_Query;

export type CheckoutLinesCountQuery_checkout_Checkout_lines_CheckoutLine = { quantity: number };

export type CheckoutLinesCountQuery_checkout_Checkout = { lines: Array<CheckoutLinesCountQuery_checkout_Checkout_lines_CheckoutLine> };

export type CheckoutLinesCountQuery_Query = { checkout: CheckoutLinesCountQuery_checkout_Checkout | null };


export type CheckoutLinesCountQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type CheckoutLinesCountQuery = CheckoutLinesCountQuery_Query;

export type CheckoutQuery_checkout_Checkout_billingAddress_Address_country_CountryDisplay = { code: string };

export type CheckoutQuery_checkout_Checkout_billingAddress_Address = { id: string, firstName: string, lastName: string, companyName: string, streetAddress1: string, streetAddress2: string, city: string, postalCode: string, countryArea: string, phone: string | null, isDefaultBillingAddress: boolean | null, isDefaultShippingAddress: boolean | null, country: CheckoutQuery_checkout_Checkout_billingAddress_Address_country_CountryDisplay };

export type CheckoutQuery_checkout_Checkout_shippingAddress_Address = { id: string, firstName: string, lastName: string, companyName: string, streetAddress1: string, streetAddress2: string, city: string, postalCode: string, countryArea: string, phone: string | null, isDefaultBillingAddress: boolean | null, isDefaultShippingAddress: boolean | null, country: CheckoutQuery_checkout_Checkout_billingAddress_Address_country_CountryDisplay };

export type CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod_translation_ShippingMethodTranslation = { name: string | null, description: string | null };

export type CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod_price_Money = { amount: number, currency: string };

export type CheckoutQuery_checkout_Checkout_deliveryMethod_Warehouse_address_Address = { id: string, firstName: string, lastName: string, companyName: string, streetAddress1: string, streetAddress2: string, city: string, postalCode: string, countryArea: string, phone: string | null, isDefaultBillingAddress: boolean | null, isDefaultShippingAddress: boolean | null, country: CheckoutQuery_checkout_Checkout_billingAddress_Address_country_CountryDisplay };

export type CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod = (
  { id: string, name: string, description: string | null, minimumDeliveryDays: number | null, maximumDeliveryDays: number | null, translation: CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod_translation_ShippingMethodTranslation | null, price: CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod_price_Money }
  & { __typename: 'ShippingMethod' }
);

export type CheckoutQuery_checkout_Checkout_deliveryMethod_Warehouse = (
  { id: string, name: string, address: CheckoutQuery_checkout_Checkout_deliveryMethod_Warehouse_address_Address }
  & { __typename: 'Warehouse' }
);

export type CheckoutQuery_checkout_Checkout_deliveryMethod = CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod | CheckoutQuery_checkout_Checkout_deliveryMethod_Warehouse;

export type CheckoutQuery_checkout_Checkout_shippingMethods_ShippingMethod = (
  { id: string, name: string, description: string | null, minimumDeliveryDays: number | null, maximumDeliveryDays: number | null, translation: CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod_translation_ShippingMethodTranslation | null, price: CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod_price_Money }
  & { __typename: 'ShippingMethod' }
);

export type CheckoutQuery_checkout_Checkout_discount_Money = { amount: number, currency: string };

export type CheckoutQuery_checkout_Checkout_giftCards_GiftCard_currentBalance_Money = { amount: number, currency: string };

export type CheckoutQuery_checkout_Checkout_giftCards_GiftCard = { displayCode: string, id: string, isActive: boolean, currentBalance: CheckoutQuery_checkout_Checkout_giftCards_GiftCard_currentBalance_Money };

export type CheckoutQuery_checkout_Checkout_availableCollectionPoints_Warehouse = (
  { id: string, name: string, address: CheckoutQuery_checkout_Checkout_deliveryMethod_Warehouse_address_Address }
  & { __typename: 'Warehouse' }
);

export type CheckoutQuery_checkout_Checkout_availablePaymentGateways_PaymentGateway_config_GatewayConfigLine = { field: string, value: string | null };

export type CheckoutQuery_checkout_Checkout_availablePaymentGateways_PaymentGateway = { name: string, id: string, currencies: Array<string>, config: Array<CheckoutQuery_checkout_Checkout_availablePaymentGateways_PaymentGateway_config_GatewayConfigLine> };

export type CheckoutQuery_checkout_Checkout_subtotalPrice_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type CheckoutQuery_checkout_Checkout = { isShippingRequired: boolean, voucherCode: string | null, id: string, quantity: number, email: string | null, displayGrossPrices: boolean, taxNumber: string | null, shippingInfo: string | null, billingAddress: CheckoutQuery_checkout_Checkout_billingAddress_Address | null, shippingAddress: CheckoutQuery_checkout_Checkout_shippingAddress_Address | null, deliveryMethod: CheckoutQuery_checkout_Checkout_deliveryMethod | null, shippingMethods: Array<CheckoutQuery_checkout_Checkout_shippingMethods_ShippingMethod>, discount: CheckoutQuery_checkout_Checkout_discount_Money | null, giftCards: Array<CheckoutQuery_checkout_Checkout_giftCards_GiftCard>, availableCollectionPoints: Array<CheckoutQuery_checkout_Checkout_availableCollectionPoints_Warehouse>, availablePaymentGateways: Array<CheckoutQuery_checkout_Checkout_availablePaymentGateways_PaymentGateway>, subtotalPrice: CheckoutQuery_checkout_Checkout_subtotalPrice_TaxedMoney, totalPrice: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney, lines: Array<CheckoutBaseQuery_checkout_Checkout_lines_CheckoutLine>, channel: CheckoutBaseQuery_checkout_Checkout_channel_Channel, user: CheckoutBaseQuery_checkout_Checkout_user_User | null };

export type CheckoutQuery_Query = { checkout: CheckoutQuery_checkout_Checkout | null };


export type CheckoutQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  countryCode: Types.CountryCode;
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
  thumbnailFormat: Types.ThumbnailFormatEnum;
}>;


export type CheckoutQuery = CheckoutQuery_Query;

export type CollectionDetailsQuery_collection_Collection_translation_CollectionTranslation = { description: string | null, name: string | null };

export type CollectionDetailsQuery_collection_Collection_thumbnail_Image = { alt: string | null, url: string };

export type CollectionDetailsQuery_collection_Collection_ogThumbnail_Image = { alt: string | null, url: string };

export type CollectionDetailsQuery_collection_Collection = { id: string, description: string | null, name: string, slug: string, translation: CollectionDetailsQuery_collection_Collection_translation_CollectionTranslation | null, thumbnail: CollectionDetailsQuery_collection_Collection_thumbnail_Image | null, ogThumbnail: CollectionDetailsQuery_collection_Collection_ogThumbnail_Image | null };

export type CollectionDetailsQuery_Query = { collection: CollectionDetailsQuery_collection_Collection | null };


export type CollectionDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  channel: Types.Scalars['String']['input'];
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
  thumbnailFormat: Types.ThumbnailFormatEnum;
  ogThumbnailSize: Types.Scalars['Int']['input'];
}>;


export type CollectionDetailsQuery = CollectionDetailsQuery_Query;

export type CollectionsPathQuery_collections_CollectionCountableConnection_pageInfo_PageInfo = { hasNextPage: boolean, hasPreviousPage: boolean, endCursor: string | null, startCursor: string | null };

export type CollectionsPathQuery_collections_CollectionCountableConnection_edges_CollectionCountableEdge_node_Collection = { slug: string };

export type CollectionsPathQuery_collections_CollectionCountableConnection_edges_CollectionCountableEdge = { node: CollectionsPathQuery_collections_CollectionCountableConnection_edges_CollectionCountableEdge_node_Collection };

export type CollectionsPathQuery_collections_CollectionCountableConnection = { pageInfo: CollectionsPathQuery_collections_CollectionCountableConnection_pageInfo_PageInfo, edges: Array<CollectionsPathQuery_collections_CollectionCountableConnection_edges_CollectionCountableEdge> };

export type CollectionsPathQuery_Query = { collections: CollectionsPathQuery_collections_CollectionCountableConnection | null };


export type CollectionsPathQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.CollectionFilterInput>;
  channel: Types.Scalars['String']['input'];
}>;


export type CollectionsPathQuery = CollectionsPathQuery_Query;

export type FeaturedCategoriesQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category_products_ProductCountableConnection = { totalCount: number | null };

export type FeaturedCategoriesQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category_thumbnail_Image = { alt: string | null, url: string };

export type FeaturedCategoriesQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category = { slug: string, name: string, products: FeaturedCategoriesQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category_products_ProductCountableConnection | null, thumbnail: FeaturedCategoriesQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category_thumbnail_Image | null, translation: CategoryCategoriesQueries_category_Category_parent_Category_parent_Category_parent_Category_parent_Category_parent_Category_translation_CategoryTranslation | null };

export type FeaturedCategoriesQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge = { node: FeaturedCategoriesQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category };

export type FeaturedCategoriesQuery_categories_CategoryCountableConnection = { edges: Array<FeaturedCategoriesQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge> };

export type FeaturedCategoriesQuery_Query = { categories: FeaturedCategoriesQuery_categories_CategoryCountableConnection | null };


export type FeaturedCategoriesQueryVariables = Types.Exact<{
  channel: Types.Scalars['String']['input'];
  languageCode: Types.LanguageCodeEnum;
  first: Types.Scalars['Int']['input'];
  thumbnailSize: Types.Scalars['Int']['input'];
  thumbnailFormat: Types.ThumbnailFormatEnum;
}>;


export type FeaturedCategoriesQuery = FeaturedCategoriesQuery_Query;

export type OrderByPaymentQuery_payment_Payment_order_Order = { id: string };

export type OrderByPaymentQuery_payment_Payment_checkout_Checkout = { id: string };

export type OrderByPaymentQuery_payment_Payment = { order: OrderByPaymentQuery_payment_Payment_order_Order | null, checkout: OrderByPaymentQuery_payment_Payment_checkout_Checkout | null };

export type OrderByPaymentQuery_Query = { payment: OrderByPaymentQuery_payment_Payment | null };


export type OrderByPaymentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type OrderByPaymentQuery = OrderByPaymentQuery_Query;

export type OrderDetailsQuery_order_Order_shippingAddress_Address = { id: string, firstName: string, lastName: string, companyName: string, streetAddress1: string, streetAddress2: string, city: string, postalCode: string, countryArea: string, phone: string | null, isDefaultBillingAddress: boolean | null, isDefaultShippingAddress: boolean | null, country: CheckoutQuery_checkout_Checkout_billingAddress_Address_country_CountryDisplay };

export type OrderDetailsQuery_order_Order_billingAddress_Address = { id: string, firstName: string, lastName: string, companyName: string, streetAddress1: string, streetAddress2: string, city: string, postalCode: string, countryArea: string, phone: string | null, isDefaultBillingAddress: boolean | null, isDefaultShippingAddress: boolean | null, country: CheckoutQuery_checkout_Checkout_billingAddress_Address_country_CountryDisplay };

export type OrderDetailsQuery_order_Order_lines_OrderLine_variant_ProductVariant_product_Product = { id: string, slug: string };

export type OrderDetailsQuery_order_Order_lines_OrderLine_variant_ProductVariant = { id: string, product: OrderDetailsQuery_order_Order_lines_OrderLine_variant_ProductVariant_product_Product };

export type OrderDetailsQuery_order_Order_lines_OrderLine_thumbnail_Image = { alt: string | null, url: string };

export type OrderDetailsQuery_order_Order_lines_OrderLine_unitPrice_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type OrderDetailsQuery_order_Order_lines_OrderLine_undiscountedUnitPrice_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type OrderDetailsQuery_order_Order_lines_OrderLine = { id: string, quantity: number, productName: string, variantName: string, translatedProductName: string, translatedVariantName: string, variant: OrderDetailsQuery_order_Order_lines_OrderLine_variant_ProductVariant | null, thumbnail: OrderDetailsQuery_order_Order_lines_OrderLine_thumbnail_Image | null, unitPrice: OrderDetailsQuery_order_Order_lines_OrderLine_unitPrice_TaxedMoney, undiscountedUnitPrice: OrderDetailsQuery_order_Order_lines_OrderLine_undiscountedUnitPrice_TaxedMoney };

export type OrderDetailsQuery_order_Order_total_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type OrderDetailsQuery_order_Order_subtotal_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type OrderDetailsQuery_order_Order_deliveryMethod_ShippingMethod = (
  { id: string, name: string, description: string | null, minimumDeliveryDays: number | null, maximumDeliveryDays: number | null, translation: CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod_translation_ShippingMethodTranslation | null, price: CheckoutQuery_checkout_Checkout_deliveryMethod_ShippingMethod_price_Money }
  & { __typename: 'ShippingMethod' }
);

export type OrderDetailsQuery_order_Order_deliveryMethod_Warehouse = (
  { id: string, name: string, address: CheckoutQuery_checkout_Checkout_deliveryMethod_Warehouse_address_Address }
  & { __typename: 'Warehouse' }
);

export type OrderDetailsQuery_order_Order_deliveryMethod = OrderDetailsQuery_order_Order_deliveryMethod_ShippingMethod | OrderDetailsQuery_order_Order_deliveryMethod_Warehouse;

export type OrderDetailsQuery_order_Order_voucher_Voucher = { code: string | null, currency: string | null, discountValue: number | null };

export type OrderDetailsQuery_order_Order_fulfillments_Fulfillment = { trackingNumber: string };

export type OrderDetailsQuery_order_Order = { id: string, number: string, userEmail: string | null, isShippingRequired: boolean, collectionPointName: string | null, displayGrossPrices: boolean, paymentStatus: Types.PaymentChargeStatusEnum, taxNumber: string | null, shippingInfo: string | null, shippingAddress: OrderDetailsQuery_order_Order_shippingAddress_Address | null, billingAddress: OrderDetailsQuery_order_Order_billingAddress_Address | null, lines: Array<OrderDetailsQuery_order_Order_lines_OrderLine>, total: OrderDetailsQuery_order_Order_total_TaxedMoney, subtotal: OrderDetailsQuery_order_Order_subtotal_TaxedMoney, deliveryMethod: OrderDetailsQuery_order_Order_deliveryMethod | null, voucher: OrderDetailsQuery_order_Order_voucher_Voucher | null, fulfillments: Array<OrderDetailsQuery_order_Order_fulfillments_Fulfillment> };

export type OrderDetailsQuery_Query = { order: OrderDetailsQuery_order_Order | null };


export type OrderDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
  thumbnailFormat: Types.ThumbnailFormatEnum;
}>;


export type OrderDetailsQuery = OrderDetailsQuery_Query;

export type OrderTransactionsQuery_order_Order_payments_Payment_transactions_Transaction = { token: string, isSuccess: boolean, kind: Types.TransactionKind };

export type OrderTransactionsQuery_order_Order_payments_Payment = { chargeStatus: Types.PaymentChargeStatusEnum, pspReference: string | null, transactions: Array<OrderTransactionsQuery_order_Order_payments_Payment_transactions_Transaction> | null };

export type OrderTransactionsQuery_order_Order = { paymentStatus: Types.PaymentChargeStatusEnum, payments: Array<OrderTransactionsQuery_order_Order_payments_Payment> };

export type OrderTransactionsQuery_Query = { order: OrderTransactionsQuery_order_Order | null };


export type OrderTransactionsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type OrderTransactionsQuery = OrderTransactionsQuery_Query;

export type PageQuery_page_Page_translation_PageTranslation = { title: string | null, content: string | null };

export type PageQuery_page_Page = { slug: string, title: string, content: string | null, translation: PageQuery_page_Page_translation_PageTranslation | null };

export type PageQuery_Query = { page: PageQuery_page_Page | null };


export type PageQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  languageCode: Types.LanguageCodeEnum;
}>;


export type PageQuery = PageQuery_Query;

export type PagesTypeQuery_pageTypes_PageTypeCountableConnection_edges_PageTypeCountableEdge_node_PageType = { id: string, slug: string };

export type PagesTypeQuery_pageTypes_PageTypeCountableConnection_edges_PageTypeCountableEdge = { node: PagesTypeQuery_pageTypes_PageTypeCountableConnection_edges_PageTypeCountableEdge_node_PageType };

export type PagesTypeQuery_pageTypes_PageTypeCountableConnection = { edges: Array<PagesTypeQuery_pageTypes_PageTypeCountableConnection_edges_PageTypeCountableEdge> };

export type PagesTypeQuery_Query = { pageTypes: PagesTypeQuery_pageTypes_PageTypeCountableConnection | null };


export type PagesTypeQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
}>;


export type PagesTypeQuery = PagesTypeQuery_Query;

export type ProductAvailabilityInfoQuery_product_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_start_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type ProductAvailabilityInfoQuery_product_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_stop_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type ProductAvailabilityInfoQuery_product_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange = { start: ProductAvailabilityInfoQuery_product_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_start_TaxedMoney | null, stop: ProductAvailabilityInfoQuery_product_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_stop_TaxedMoney | null };

export type ProductAvailabilityInfoQuery_product_Product_pricing_ProductPricingInfo = { displayGrossPrices: boolean, priceRange: ProductAvailabilityInfoQuery_product_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange | null };

export type ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_discount_TaxedMoney = { currency: string };

export type ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_price_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_priceUndiscounted_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo = { onSale: boolean | null, discount: ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_discount_TaxedMoney | null, price: ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_price_TaxedMoney | null, priceUndiscounted: ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_priceUndiscounted_TaxedMoney | null };

export type ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant = { id: string, quantityLimitPerCustomer: number | null, quantityAvailable: number | null, pricing: ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo | null };

export type ProductAvailabilityInfoQuery_product_Product = { id: string, isAvailable: boolean | null, pricing: ProductAvailabilityInfoQuery_product_Product_pricing_ProductPricingInfo | null, variantsAvailability: Array<ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant> | null };

export type ProductAvailabilityInfoQuery_Query = { product: ProductAvailabilityInfoQuery_product_Product | null };


export type ProductAvailabilityInfoQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  channel: Types.Scalars['String']['input'];
  countryCode: Types.CountryCode;
}>;


export type ProductAvailabilityInfoQuery = ProductAvailabilityInfoQuery_Query;

export type ProductCardsQuery_products_ProductCountableConnection_pageInfo_PageInfo = { hasNextPage: boolean, hasPreviousPage: boolean, endCursor: string | null, startCursor: string | null };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_variants_ProductVariant = { id: string, name: string };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_translation_ProductTranslation = { description: string | null, name: string | null };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_media_ProductMedia = { alt: string, type: Types.ProductMediaType, thumbnail: string };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_discount_TaxedMoney = { currency: string };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_start_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_stop_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange = { start: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_start_TaxedMoney | null, stop: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_stop_TaxedMoney | null };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRangeUndiscounted_TaxedMoneyRange = { start: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_start_TaxedMoney | null, stop: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_stop_TaxedMoney | null };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo = { onSale: boolean | null, displayGrossPrices: boolean, discount: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_discount_TaxedMoney | null, priceRange: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange | null, priceRangeUndiscounted: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRangeUndiscounted_TaxedMoneyRange | null };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product = { created: string, id: string, slug: string, description: string | null, name: string, isAvailable: boolean | null, variants: Array<ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_variants_ProductVariant> | null, translation: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_translation_ProductTranslation | null, media: Array<ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_media_ProductMedia> | null, pricing: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo | null };

export type ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge = { node: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product };

export type ProductCardsQuery_products_ProductCountableConnection = { pageInfo: ProductCardsQuery_products_ProductCountableConnection_pageInfo_PageInfo, edges: Array<ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge> };

export type ProductCardsQuery_Query = { products: ProductCardsQuery_products_ProductCountableConnection | null };


export type ProductCardsQueryVariables = Types.Exact<{
  channel: Types.Scalars['String']['input'];
  countryCode: Types.CountryCode;
  filter?: Types.InputMaybe<Types.ProductFilterInput>;
  where?: Types.InputMaybe<Types.ProductWhereInput>;
  sort?: Types.InputMaybe<Types.ProductOrder>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  thumbnailFormat: Types.ThumbnailFormatEnum;
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
}>;


export type ProductCardsQuery = ProductCardsQuery_Query;

export type ProductCategoriesQuery_product_Product_category_Category = { slug: string, name: string, translation: CategoryCategoriesQueries_category_Category_translation_CategoryTranslation | null, parent: CategoryCategoriesQueries_category_Category_parent_Category | null };

export type ProductCategoriesQuery_product_Product = { category: ProductCategoriesQuery_product_Product_category_Category | null };

export type ProductCategoriesQuery_Query = { product: ProductCategoriesQuery_product_Product | null };


export type ProductCategoriesQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  channel: Types.Scalars['String']['input'];
  languageCode: Types.LanguageCodeEnum;
}>;


export type ProductCategoriesQuery = ProductCategoriesQuery_Query;

export type ProductDetailsQuery_product_Product_ogThumbnail_Image = { alt: string | null, url: string };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_weight_Weight = { value: number, unit: Types.WeightUnitsEnum };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_attribute_Attribute_translation_AttributeTranslation = { name: string };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_attribute_Attribute = { name: string | null, slug: string | null, id: string, inputType: Types.AttributeInputTypeEnum | null, unit: Types.MeasurementUnitsEnum | null, translation: ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_attribute_Attribute_translation_AttributeTranslation | null };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_values_AttributeValue_translation_AttributeValueTranslation = { name: string, richText: string | null, plainText: string | null };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_values_AttributeValue = { id: string, slug: string | null, name: string | null, richText: string | null, plainText: string | null, boolean: boolean | null, date: string | null, dateTime: string | null, value: string | null, translation: ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_values_AttributeValue_translation_AttributeValueTranslation | null };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute = { attribute: ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_attribute_Attribute, values: Array<ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_values_AttributeValue> };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_translation_ProductVariantTranslation = { name: string };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_pricing_VariantPricingInfo = { onSale: boolean | null, discount: ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_discount_TaxedMoney | null, price: ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_price_TaxedMoney | null, priceUndiscounted: ProductAvailabilityInfoQuery_product_Product_variantsAvailability_ProductVariant_pricing_VariantPricingInfo_priceUndiscounted_TaxedMoney | null };

export type ProductDetailsQuery_product_Product_variants_ProductVariant_media_ProductMedia = { alt: string, type: Types.ProductMediaType, thumbnail: string };

export type ProductDetailsQuery_product_Product_variants_ProductVariant = { id: string, name: string, sku: string | null, quantityAvailable: number | null, quantityLimitPerCustomer: number | null, weight: ProductDetailsQuery_product_Product_variants_ProductVariant_weight_Weight | null, attributes: Array<ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute>, translation: ProductDetailsQuery_product_Product_variants_ProductVariant_translation_ProductVariantTranslation | null, pricing: ProductDetailsQuery_product_Product_variants_ProductVariant_pricing_VariantPricingInfo | null, media: Array<ProductDetailsQuery_product_Product_variants_ProductVariant_media_ProductMedia> | null };

export type ProductDetailsQuery_product_Product_productType_ProductType_weight_Weight = { value: number, unit: Types.WeightUnitsEnum };

export type ProductDetailsQuery_product_Product_productType_ProductType = { weight: ProductDetailsQuery_product_Product_productType_ProductType_weight_Weight | null };

export type ProductDetailsQuery_product_Product_weight_Weight = { value: number, unit: Types.WeightUnitsEnum };

export type ProductDetailsQuery_product_Product_attributes_SelectedAttribute = { attribute: ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_attribute_Attribute, values: Array<ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_values_AttributeValue> };

export type ProductDetailsQuery_product_Product_category_Category = { id: string, name: string, slug: string, description: string | null, translation: CategoryDetailsQuery_category_Category_translation_CategoryTranslation | null };

export type ProductDetailsQuery_product_Product = { id: string, slug: string, description: string | null, name: string, isAvailable: boolean | null, ogThumbnail: ProductDetailsQuery_product_Product_ogThumbnail_Image | null, variants: Array<ProductDetailsQuery_product_Product_variants_ProductVariant> | null, productType: ProductDetailsQuery_product_Product_productType_ProductType, weight: ProductDetailsQuery_product_Product_weight_Weight | null, attributes: Array<ProductDetailsQuery_product_Product_attributes_SelectedAttribute>, category: ProductDetailsQuery_product_Product_category_Category | null, translation: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_translation_ProductTranslation | null, media: Array<ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_media_ProductMedia> | null, pricing: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo | null };

export type ProductDetailsQuery_Query = { product: ProductDetailsQuery_product_Product | null };


export type ProductDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  channel: Types.Scalars['String']['input'];
  countryCode: Types.CountryCode;
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
  thumbnailFormat: Types.ThumbnailFormatEnum;
  ogThumbnailSize: Types.Scalars['Int']['input'];
}>;


export type ProductDetailsQuery = ProductDetailsQuery_Query;

export type ProductDetailsRawDetailsQuery_product_Product_translation_ProductTranslation = { name: string | null };

export type ProductDetailsRawDetailsQuery_product_Product_thumbnail_Image = { alt: string | null, url: string };

export type ProductDetailsRawDetailsQuery_product_Product_productType_ProductType = { name: string };

export type ProductDetailsRawDetailsQuery_product_Product_category_Category = { id: string, name: string, slug: string, description: string | null, translation: CategoryDetailsQuery_category_Category_translation_CategoryTranslation | null };

export type ProductDetailsRawDetailsQuery_product_Product = { id: string, slug: string, name: string, translation: ProductDetailsRawDetailsQuery_product_Product_translation_ProductTranslation | null, thumbnail: ProductDetailsRawDetailsQuery_product_Product_thumbnail_Image | null, productType: ProductDetailsRawDetailsQuery_product_Product_productType_ProductType, category: ProductDetailsRawDetailsQuery_product_Product_category_Category | null };

export type ProductDetailsRawDetailsQuery_Query = { product: ProductDetailsRawDetailsQuery_product_Product | null };


export type ProductDetailsRawDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  channel: Types.Scalars['String']['input'];
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
  thumbnailFormat: Types.ThumbnailFormatEnum;
}>;


export type ProductDetailsRawDetailsQuery = ProductDetailsRawDetailsQuery_Query;

export type ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute_choices_AttributeValueCountableConnection_edges_AttributeValueCountableEdge_node_AttributeValue = { id: string, slug: string | null, name: string | null, richText: string | null, plainText: string | null, boolean: boolean | null, date: string | null, dateTime: string | null, value: string | null, translation: ProductDetailsQuery_product_Product_variants_ProductVariant_attributes_SelectedAttribute_values_AttributeValue_translation_AttributeValueTranslation | null };

export type ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute_choices_AttributeValueCountableConnection_edges_AttributeValueCountableEdge = { node: ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute_choices_AttributeValueCountableConnection_edges_AttributeValueCountableEdge_node_AttributeValue };

export type ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute_choices_AttributeValueCountableConnection = { edges: Array<ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute_choices_AttributeValueCountableConnection_edges_AttributeValueCountableEdge> };

export type ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute_translation_AttributeTranslation = { name: string };

export type ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute = { withChoices: boolean, name: string | null, slug: string | null, inputType: Types.AttributeInputTypeEnum | null, choices: ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute_choices_AttributeValueCountableConnection | null, translation: ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute_translation_AttributeTranslation | null };

export type ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge = { node: ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge_node_Attribute };

export type ProductFiltersQuery_attributes_AttributeCountableConnection = { edges: Array<ProductFiltersQuery_attributes_AttributeCountableConnection_edges_AttributeCountableEdge> };

export type ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_stop_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange = { stop: ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_stop_TaxedMoney | null };

export type ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo = { priceRange: ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange | null };

export type ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product = { pricing: ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo | null };

export type ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge = { node: ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product };

export type ProductFiltersQuery_maxProductsPrice_ProductCountableConnection = { edges: Array<ProductFiltersQuery_maxProductsPrice_ProductCountableConnection_edges_ProductCountableEdge> };

export type ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_start_TaxedMoney = { gross: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_gross_Money, net: CheckoutBaseQuery_checkout_Checkout_totalPrice_TaxedMoney_net_Money };

export type ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange = { start: ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange_start_TaxedMoney | null };

export type ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo = { priceRange: ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo_priceRange_TaxedMoneyRange | null };

export type ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product = { pricing: ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo | null };

export type ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge = { node: ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge_node_Product };

export type ProductFiltersQuery_minProductsPrice_ProductCountableConnection = { edges: Array<ProductFiltersQuery_minProductsPrice_ProductCountableConnection_edges_ProductCountableEdge> };

export type ProductFiltersQuery_Query = { attributes: ProductFiltersQuery_attributes_AttributeCountableConnection | null, maxProductsPrice: ProductFiltersQuery_maxProductsPrice_ProductCountableConnection | null, minProductsPrice: ProductFiltersQuery_minProductsPrice_ProductCountableConnection | null };


export type ProductFiltersQueryVariables = Types.Exact<{
  channel: Types.Scalars['String']['input'];
  languageCode: Types.LanguageCodeEnum;
}>;


export type ProductFiltersQuery = ProductFiltersQuery_Query;

export type ProductListingQuery_products_ProductCountableConnection_pageInfo_PageInfo = { hasNextPage: boolean, hasPreviousPage: boolean, endCursor: string | null, startCursor: string | null };

export type ProductListingQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_variants_ProductVariant = { id: string, name: string };

export type ProductListingQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product = { created: string, id: string, slug: string, description: string | null, name: string, isAvailable: boolean | null, variants: Array<ProductListingQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_variants_ProductVariant> | null, translation: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_translation_ProductTranslation | null, media: Array<ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_media_ProductMedia> | null, pricing: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo | null };

export type ProductListingQuery_products_ProductCountableConnection_edges_ProductCountableEdge = { node: ProductListingQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product };

export type ProductListingQuery_products_ProductCountableConnection = { totalCount: number | null, pageInfo: ProductListingQuery_products_ProductCountableConnection_pageInfo_PageInfo, edges: Array<ProductListingQuery_products_ProductCountableConnection_edges_ProductCountableEdge> };

export type ProductListingQuery_Query = { products: ProductListingQuery_products_ProductCountableConnection | null };


export type ProductListingQueryVariables = Types.Exact<{
  channel: Types.Scalars['String']['input'];
  countryCode: Types.CountryCode;
  filter?: Types.InputMaybe<Types.ProductFilterInput>;
  where?: Types.InputMaybe<Types.ProductWhereInput>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.ProductOrder>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  thumbnailFormat: Types.ThumbnailFormatEnum;
  languageCode: Types.LanguageCodeEnum;
  thumbnailSize: Types.Scalars['Int']['input'];
}>;


export type ProductListingQuery = ProductListingQuery_Query;

export type ProductSlugQuery_product_Product = { slug: string };

export type ProductSlugQuery_Query = { product: ProductSlugQuery_product_Product | null };


export type ProductSlugQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProductSlugQuery = ProductSlugQuery_Query;

export type ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection_pageInfo_PageInfo = { hasNextPage: boolean, hasPreviousPage: boolean, endCursor: string | null, startCursor: string | null };

export type ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection_edges_ProductVariantCountableEdge_node_ProductVariant_product_Product = { id: string, slug: string, description: string | null, name: string, isAvailable: boolean | null, translation: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_translation_ProductTranslation | null, media: Array<ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_media_ProductMedia> | null, pricing: ProductCardsQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_pricing_ProductPricingInfo | null };

export type ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection_edges_ProductVariantCountableEdge_node_ProductVariant = { created: string, id: string, name: string, sku: string | null, quantityAvailable: number | null, quantityLimitPerCustomer: number | null, product: ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection_edges_ProductVariantCountableEdge_node_ProductVariant_product_Product, translation: ProductDetailsQuery_product_Product_variants_ProductVariant_translation_ProductVariantTranslation | null, pricing: ProductDetailsQuery_product_Product_variants_ProductVariant_pricing_VariantPricingInfo | null, media: Array<ProductDetailsQuery_product_Product_variants_ProductVariant_media_ProductMedia> | null };

export type ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection_edges_ProductVariantCountableEdge = { node: ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection_edges_ProductVariantCountableEdge_node_ProductVariant };

export type ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection = { pageInfo: ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection_pageInfo_PageInfo, edges: Array<ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection_edges_ProductVariantCountableEdge> };

export type ProductVariantCardsQuery_Query = { productVariants: ProductVariantCardsQuery_productVariants_ProductVariantCountableConnection | null };


export type ProductVariantCardsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  channel: Types.Scalars['String']['input'];
  sort?: Types.InputMaybe<Types.ProductVariantSortingInput>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  thumbnailFormat: Types.ThumbnailFormatEnum;
  languageCode: Types.LanguageCodeEnum;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  thumbnailSize: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.ProductVariantFilterInput>;
  countryCode: Types.CountryCode;
}>;


export type ProductVariantCardsQuery = ProductVariantCardsQuery_Query;

export type ProductsPathQuery_products_ProductCountableConnection_pageInfo_PageInfo = { hasNextPage: boolean, hasPreviousPage: boolean, endCursor: string | null, startCursor: string | null };

export type ProductsPathQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product = { slug: string };

export type ProductsPathQuery_products_ProductCountableConnection_edges_ProductCountableEdge = { node: ProductsPathQuery_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product };

export type ProductsPathQuery_products_ProductCountableConnection = { pageInfo: ProductsPathQuery_products_ProductCountableConnection_pageInfo_PageInfo, edges: Array<ProductsPathQuery_products_ProductCountableConnection_edges_ProductCountableEdge> };

export type ProductsPathQuery_Query = { products: ProductsPathQuery_products_ProductCountableConnection | null };


export type ProductsPathQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  channel: Types.Scalars['String']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ProductFilterInput>;
}>;


export type ProductsPathQuery = ProductsPathQuery_Query;

export type ProductsSearch_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_translation_ProductTranslation = { name: string | null };

export type ProductsSearch_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product = { slug: string, name: string, translation: ProductsSearch_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product_translation_ProductTranslation | null };

export type ProductsSearch_products_ProductCountableConnection_edges_ProductCountableEdge = { node: ProductsSearch_products_ProductCountableConnection_edges_ProductCountableEdge_node_Product };

export type ProductsSearch_products_ProductCountableConnection = { edges: Array<ProductsSearch_products_ProductCountableConnection_edges_ProductCountableEdge> };

export type ProductsSearch_Query = { products: ProductsSearch_products_ProductCountableConnection | null };


export type ProductsSearchVariables = Types.Exact<{
  channel: Types.Scalars['String']['input'];
  filter?: Types.InputMaybe<Types.ProductFilterInput>;
  languageCode: Types.LanguageCodeEnum;
  limit: Types.Scalars['Int']['input'];
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ProductsSearch = ProductsSearch_Query;

export type PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page_translation_PageTranslation = { content: string | null };

export type PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page_attributes_SelectedAttribute_attribute_Attribute = { slug: string | null };

export type PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page_attributes_SelectedAttribute_values_AttributeValue = { dateTime: string | null };

export type PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page_attributes_SelectedAttribute = { attribute: PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page_attributes_SelectedAttribute_attribute_Attribute, values: Array<PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page_attributes_SelectedAttribute_values_AttributeValue> };

export type PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page = { content: string | null, translation: PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page_translation_PageTranslation | null, attributes: Array<PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page_attributes_SelectedAttribute> };

export type PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge = { node: PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge_node_Page };

export type PromotionsBarItemsQuery_pages_PageCountableConnection = { edges: Array<PromotionsBarItemsQuery_pages_PageCountableConnection_edges_PageCountableEdge> };

export type PromotionsBarItemsQuery_Query = { pages: PromotionsBarItemsQuery_pages_PageCountableConnection | null };


export type PromotionsBarItemsQueryVariables = Types.Exact<{
  languageCode: Types.LanguageCodeEnum;
  pageTypeID: Types.Scalars['ID']['input'];
}>;


export type PromotionsBarItemsQuery = PromotionsBarItemsQuery_Query;

export type ShopQuery_shop_Shop_countries_CountryDisplay = { code: string };

export type ShopQuery_shop_Shop = { countries: Array<ShopQuery_shop_Shop_countries_CountryDisplay> };

export type ShopQuery_Query = { shop: ShopQuery_shop_Shop };


export type ShopQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopQuery = ShopQuery_Query;

export type SiteConfigQuery_shop_Shop_countries_CountryDisplay = { code: string };

export type SiteConfigQuery_shop_Shop_companyAddress_Address = { id: string, firstName: string, lastName: string, companyName: string, streetAddress1: string, streetAddress2: string, city: string, postalCode: string, countryArea: string, phone: string | null, isDefaultBillingAddress: boolean | null, isDefaultShippingAddress: boolean | null, email: string | null, nip: string | null, regon: string | null, description: string | null, country: CheckoutQuery_checkout_Checkout_billingAddress_Address_country_CountryDisplay };

export type SiteConfigQuery_shop_Shop = { countries: Array<SiteConfigQuery_shop_Shop_countries_CountryDisplay>, companyAddress: SiteConfigQuery_shop_Shop_companyAddress_Address | null };

export type SiteConfigQuery_channel_Channel_countries_CountryDisplay = { code: string };

export type SiteConfigQuery_channel_Channel = { countries: Array<SiteConfigQuery_channel_Channel_countries_CountryDisplay> | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_translation_MenuItemTranslation = { name: string };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category_translation_CategoryTranslation = { name: string | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category = { id: string, slug: string, name: string, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category_translation_CategoryTranslation | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection_translation_CollectionTranslation = { name: string | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection = { id: string, slug: string, name: string, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection_translation_CollectionTranslation | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page_translation_PageTranslation = { title: string | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page = { id: string, slug: string, title: string, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page_translation_PageTranslation | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem = { id: string, name: string, url: string | null, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_translation_MenuItemTranslation | null, category: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category | null, collection: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection | null, page: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem = { id: string, name: string, url: string | null, children: Array<SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem> | null, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_translation_MenuItemTranslation | null, category: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category | null, collection: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection | null, page: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem = { id: string, name: string, url: string | null, children: Array<SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem> | null, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_translation_MenuItemTranslation | null, category: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category | null, collection: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection | null, page: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem = { id: string, name: string, url: string | null, children: Array<SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem> | null, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_translation_MenuItemTranslation | null, category: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category | null, collection: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection | null, page: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page | null };

export type SiteConfigQuery_navigation_Menu_items_MenuItem = { id: string, name: string, url: string | null, children: Array<SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem> | null, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_translation_MenuItemTranslation | null, category: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category | null, collection: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection | null, page: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page | null };

export type SiteConfigQuery_navigation_Menu = { id: string, name: string, items: Array<SiteConfigQuery_navigation_Menu_items_MenuItem> | null };

export type SiteConfigQuery_categories_CategoryCountableConnection_pageInfo_PageInfo = { hasNextPage: boolean };

export type SiteConfigQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category_translation_CategoryTranslation = { name: string | null };

export type SiteConfigQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category_products_ProductCountableConnection = { totalCount: number | null };

export type SiteConfigQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category = { slug: string, name: string, translation: SiteConfigQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category_translation_CategoryTranslation | null, products: SiteConfigQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category_products_ProductCountableConnection | null };

export type SiteConfigQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge = { node: SiteConfigQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge_node_Category };

export type SiteConfigQuery_categories_CategoryCountableConnection = { pageInfo: SiteConfigQuery_categories_CategoryCountableConnection_pageInfo_PageInfo, edges: Array<SiteConfigQuery_categories_CategoryCountableConnection_edges_CategoryCountableEdge> };

export type SiteConfigQuery_footerFirst_Menu_items_MenuItem = { id: string, name: string, url: string | null, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_translation_MenuItemTranslation | null, category: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category | null, collection: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection | null, page: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page | null };

export type SiteConfigQuery_footerFirst_Menu = { id: string, name: string, items: Array<SiteConfigQuery_footerFirst_Menu_items_MenuItem> | null };

export type SiteConfigQuery_footerSecond_Menu_items_MenuItem = { id: string, name: string, url: string | null, translation: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_translation_MenuItemTranslation | null, category: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_category_Category | null, collection: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_collection_Collection | null, page: SiteConfigQuery_navigation_Menu_items_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_children_MenuItem_page_Page | null };

export type SiteConfigQuery_footerSecond_Menu = { id: string, name: string, items: Array<SiteConfigQuery_footerSecond_Menu_items_MenuItem> | null };

export type SiteConfigQuery_Query = { shop: SiteConfigQuery_shop_Shop, channel: SiteConfigQuery_channel_Channel | null, navigation: SiteConfigQuery_navigation_Menu | null, categories: SiteConfigQuery_categories_CategoryCountableConnection | null, footerFirst: SiteConfigQuery_footerFirst_Menu | null, footerSecond: SiteConfigQuery_footerSecond_Menu | null };


export type SiteConfigQueryVariables = Types.Exact<{
  languageCode: Types.LanguageCodeEnum;
  channel: Types.Scalars['String']['input'];
}>;


export type SiteConfigQuery = SiteConfigQuery_Query;

export type TopSellingProductVariantsQuery_reportProductSales_ProductVariantCountableConnection_edges_ProductVariantCountableEdge_node_ProductVariant = { id: string };

export type TopSellingProductVariantsQuery_reportProductSales_ProductVariantCountableConnection_edges_ProductVariantCountableEdge = { node: TopSellingProductVariantsQuery_reportProductSales_ProductVariantCountableConnection_edges_ProductVariantCountableEdge_node_ProductVariant };

export type TopSellingProductVariantsQuery_reportProductSales_ProductVariantCountableConnection = { edges: Array<TopSellingProductVariantsQuery_reportProductSales_ProductVariantCountableConnection_edges_ProductVariantCountableEdge> };

export type TopSellingProductVariantsQuery_Query = { reportProductSales: TopSellingProductVariantsQuery_reportProductSales_ProductVariantCountableConnection | null };


export type TopSellingProductVariantsQueryVariables = Types.Exact<{
  channel: Types.Scalars['String']['input'];
  first: Types.Scalars['Int']['input'];
  salePeriod: Types.ReportingPeriod;
}>;


export type TopSellingProductVariantsQuery = TopSellingProductVariantsQuery_Query;

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const AddressValidationRulesQueryDocument = new TypedDocumentString(`
    query AddressValidationRulesQuery($countryCode: CountryCode!) {
  addressValidationRules(countryCode: $countryCode) {
    requiredFields
    countryAreaChoices {
      ...ChoiceValueFragment
    }
    cityChoices {
      ...ChoiceValueFragment
    }
    countryAreaType
    postalCodeType
    cityType
    postalCodeExamples
    postalCodeMatchers
  }
}
    fragment ChoiceValueFragment on ChoiceValue {
  raw
  verbose
}`) as unknown as TypedDocumentString<AddressValidationRulesQuery, AddressValidationRulesQueryVariables>;
export const CategoriesPathQueryDocument = new TypedDocumentString(`
    query CategoriesPathQuery($first: Int, $after: String, $filter: CategoryFilterInput) {
  categories(first: $first, after: $after, filter: $filter) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        slug
      }
    }
  }
}
    fragment PageInfoFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  endCursor
  startCursor
}`) as unknown as TypedDocumentString<CategoriesPathQuery, CategoriesPathQueryVariables>;
export const CategoryDetailsQueryDocument = new TypedDocumentString(`
    query CategoryDetailsQuery($slug: String!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!, $ogThumbnailSize: Int!, $thumbnailFormat: ThumbnailFormatEnum!) {
  category(slug: $slug) {
    ...CategoryDetailsFragment
  }
}
    fragment CategoryDetailsFragment on Category {
  ...CategoryBasicDetailsFragment
  thumbnail: backgroundImage(size: $thumbnailSize, format: $thumbnailFormat) {
    ...ImageFragment
  }
  ogThumbnail: backgroundImage(size: $ogThumbnailSize, format: ORIGINAL) {
    ...ImageFragment
  }
}
fragment CategoryBasicDetailsFragment on Category {
  id
  name
  slug
  description
  translation(languageCode: $languageCode) {
    name
    description
  }
}
fragment ImageFragment on Image {
  alt
  url
}`) as unknown as TypedDocumentString<CategoryDetailsQuery, CategoryDetailsQueryVariables>;
export const CategoryCategoriesQueriesDocument = new TypedDocumentString(`
    query CategoryCategoriesQueries($slug: String!, $languageCode: LanguageCodeEnum!) {
  category(slug: $slug) {
    ...CategoryUrlWithParentsFragment
  }
}
    fragment CategoryUrlWithParentsFragment on Category {
  ...CategoryUrlFragment
  translation(languageCode: $languageCode) {
    name
  }
  parent {
    ...CategoryUrlFragment
    parent {
      ...CategoryUrlFragment
      parent {
        ...CategoryUrlFragment
        parent {
          ...CategoryUrlFragment
          parent {
            ...CategoryUrlFragment
          }
        }
      }
    }
  }
}
fragment CategoryUrlFragment on Category {
  slug
  name
  translation(languageCode: $languageCode) {
    name
  }
}`) as unknown as TypedDocumentString<CategoryCategoriesQueries, CategoryCategoriesQueriesVariables>;
export const CheckoutBaseQueryDocument = new TypedDocumentString(`
    query CheckoutBaseQuery($id: ID!, $countryCode: CountryCode!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!, $thumbnailFormat: ThumbnailFormatEnum!) {
  checkout(id: $id) {
    ...CheckoutBaseFragment
  }
}
    fragment CheckoutBaseFragment on Checkout {
  id
  quantity
  email
  displayGrossPrices
  totalPrice {
    ...TaxedMoneyFragment
  }
  lines {
    ...CheckoutLineFragment
  }
  channel {
    ...ChannelFragment
  }
  user {
    ...UserBaseFragment
  }
}
fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment CheckoutLineFragment on CheckoutLine {
  id
  quantity
  totalPrice {
    ...TaxedMoneyFragment
  }
  undiscountedTotalPrice {
    ...MoneyFragment
  }
  unitPrice {
    ...TaxedMoneyFragment
  }
  variant {
    id
    name
    quantityAvailable(countryCode: $countryCode)
    quantityLimitPerCustomer
    media {
      ...ProductMediaFragment
    }
    translation(languageCode: $languageCode) {
      name
    }
    product {
      thumbnail(size: $thumbnailSize, format: $thumbnailFormat) {
        alt
        thumbnail: url
      }
      id
      slug
      name
      translation(languageCode: $languageCode) {
        name
      }
      category {
        name
      }
      pricing {
        displayGrossPrices
      }
    }
  }
}
fragment ProductMediaFragment on ProductMedia {
  alt
  type
  thumbnail: url(size: $thumbnailSize, format: $thumbnailFormat)
}
fragment ChannelFragment on Channel {
  slug
}
fragment UserBaseFragment on User {
  id
  email
}`) as unknown as TypedDocumentString<CheckoutBaseQuery, CheckoutBaseQueryVariables>;
export const CheckoutLinesCountQueryDocument = new TypedDocumentString(`
    query CheckoutLinesCountQuery($id: ID!) {
  checkout(id: $id) {
    lines {
      quantity
    }
  }
}
    `) as unknown as TypedDocumentString<CheckoutLinesCountQuery, CheckoutLinesCountQueryVariables>;
export const CheckoutQueryDocument = new TypedDocumentString(`
    query CheckoutQuery($id: ID!, $countryCode: CountryCode!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!, $thumbnailFormat: ThumbnailFormatEnum!) {
  checkout(id: $id) {
    ...CheckoutFragment
  }
}
    fragment CheckoutBaseFragment on Checkout {
  id
  quantity
  email
  displayGrossPrices
  totalPrice {
    ...TaxedMoneyFragment
  }
  lines {
    ...CheckoutLineFragment
  }
  channel {
    ...ChannelFragment
  }
  user {
    ...UserBaseFragment
  }
}
fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment CheckoutLineFragment on CheckoutLine {
  id
  quantity
  totalPrice {
    ...TaxedMoneyFragment
  }
  undiscountedTotalPrice {
    ...MoneyFragment
  }
  unitPrice {
    ...TaxedMoneyFragment
  }
  variant {
    id
    name
    quantityAvailable(countryCode: $countryCode)
    quantityLimitPerCustomer
    media {
      ...ProductMediaFragment
    }
    translation(languageCode: $languageCode) {
      name
    }
    product {
      thumbnail(size: $thumbnailSize, format: $thumbnailFormat) {
        alt
        thumbnail: url
      }
      id
      slug
      name
      translation(languageCode: $languageCode) {
        name
      }
      category {
        name
      }
      pricing {
        displayGrossPrices
      }
    }
  }
}
fragment ProductMediaFragment on ProductMedia {
  alt
  type
  thumbnail: url(size: $thumbnailSize, format: $thumbnailFormat)
}
fragment ChannelFragment on Channel {
  slug
}
fragment UserBaseFragment on User {
  id
  email
}
fragment CheckoutFragment on Checkout {
  ...CheckoutBaseFragment
  billingAddress {
    ...AddressFragment
  }
  shippingAddress {
    ...AddressFragment
  }
  deliveryMethod {
    ...DeliveryMethodFragment
  }
  shippingMethods {
    ...ShippingMethodFragment
  }
  isShippingRequired
  voucherCode
  discount {
    ...MoneyFragment
  }
  giftCards {
    ...GiftCardFragment
  }
  availableCollectionPoints {
    ...WarehouseFragment
  }
  availablePaymentGateways {
    ...PaymentGatewayFragment
  }
  subtotalPrice {
    ...TaxedMoneyFragment
  }
  ...OrderCheckoutMetadataFragment
}
fragment AddressFragment on Address {
  id
  firstName
  lastName
  companyName
  streetAddress1
  streetAddress2
  city
  postalCode
  country {
    ...CountryFragment
  }
  countryArea
  phone
  isDefaultBillingAddress
  isDefaultShippingAddress
}
fragment CountryFragment on CountryDisplay {
  code
}
fragment DeliveryMethodFragment on DeliveryMethod {
  ...WarehouseFragment
  ...ShippingMethodFragment
}
fragment WarehouseFragment on Warehouse {
  __typename
  id
  name
  address {
    ...AddressFragment
  }
}
fragment ShippingMethodFragment on ShippingMethod {
  __typename
  id
  name
  description
  translation(languageCode: $languageCode) {
    name
    description
  }
  minimumDeliveryDays
  maximumDeliveryDays
  price {
    ...MoneyFragment
  }
}
fragment GiftCardFragment on GiftCard {
  displayCode
  id
  isActive
  currentBalance {
    ...MoneyFragment
  }
}
fragment PaymentGatewayFragment on PaymentGateway {
  name
  id
  config {
    field
    value
  }
  currencies
}
fragment OrderCheckoutMetadataFragment on ObjectWithMetadata {
  taxNumber: metafield(key: "taxNumber")
  shippingInfo: metafield(key: "shippingInfo")
}`) as unknown as TypedDocumentString<CheckoutQuery, CheckoutQueryVariables>;
export const CollectionDetailsQueryDocument = new TypedDocumentString(`
    query CollectionDetailsQuery($slug: String!, $channel: String!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!, $thumbnailFormat: ThumbnailFormatEnum!, $ogThumbnailSize: Int!) {
  collection(slug: $slug, channel: $channel) {
    ...CollectionBasicDetailsFragment
  }
}
    fragment ImageFragment on Image {
  alt
  url
}
fragment CollectionBasicDetailsFragment on Collection {
  id
  ...CollectionUrlFragment
  description
  translation(languageCode: $languageCode) {
    description
    name
  }
  thumbnail: backgroundImage(size: $thumbnailSize, format: $thumbnailFormat) {
    ...ImageFragment
  }
  ogThumbnail: backgroundImage(size: $ogThumbnailSize, format: ORIGINAL) {
    ...ImageFragment
  }
}
fragment CollectionUrlFragment on Collection {
  name
  slug
  translation(languageCode: $languageCode) {
    name
  }
}`) as unknown as TypedDocumentString<CollectionDetailsQuery, CollectionDetailsQueryVariables>;
export const CollectionsPathQueryDocument = new TypedDocumentString(`
    query CollectionsPathQuery($first: Int, $after: String, $filter: CollectionFilterInput, $channel: String!) {
  collections(first: $first, after: $after, filter: $filter, channel: $channel) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        slug
      }
    }
  }
}
    fragment PageInfoFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  endCursor
  startCursor
}`) as unknown as TypedDocumentString<CollectionsPathQuery, CollectionsPathQueryVariables>;
export const FeaturedCategoriesQueryDocument = new TypedDocumentString(`
    query FeaturedCategoriesQuery($channel: String!, $languageCode: LanguageCodeEnum!, $first: Int!, $thumbnailSize: Int!, $thumbnailFormat: ThumbnailFormatEnum!) {
  categories(first: $first, where: {metadata: {key: "featured"}}) {
    edges {
      node {
        ...CategoryUrlFragment
        products(channel: $channel) {
          totalCount
        }
        thumbnail: backgroundImage(size: $thumbnailSize, format: $thumbnailFormat) {
          ...ImageFragment
        }
      }
    }
  }
}
    fragment ImageFragment on Image {
  alt
  url
}
fragment CategoryUrlFragment on Category {
  slug
  name
  translation(languageCode: $languageCode) {
    name
  }
}`) as unknown as TypedDocumentString<FeaturedCategoriesQuery, FeaturedCategoriesQueryVariables>;
export const OrderByPaymentQueryDocument = new TypedDocumentString(`
    query OrderByPaymentQuery($id: ID!) {
  payment(id: $id) {
    order {
      id
    }
    checkout {
      id
    }
  }
}
    `) as unknown as TypedDocumentString<OrderByPaymentQuery, OrderByPaymentQueryVariables>;
export const OrderDetailsQueryDocument = new TypedDocumentString(`
    query OrderDetailsQuery($id: ID!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!, $thumbnailFormat: ThumbnailFormatEnum!) {
  order(id: $id) {
    ...OrderDetailsFragment
  }
}
    fragment ImageFragment on Image {
  alt
  url
}
fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment AddressFragment on Address {
  id
  firstName
  lastName
  companyName
  streetAddress1
  streetAddress2
  city
  postalCode
  country {
    ...CountryFragment
  }
  countryArea
  phone
  isDefaultBillingAddress
  isDefaultShippingAddress
}
fragment CountryFragment on CountryDisplay {
  code
}
fragment DeliveryMethodFragment on DeliveryMethod {
  ...WarehouseFragment
  ...ShippingMethodFragment
}
fragment WarehouseFragment on Warehouse {
  __typename
  id
  name
  address {
    ...AddressFragment
  }
}
fragment ShippingMethodFragment on ShippingMethod {
  __typename
  id
  name
  description
  translation(languageCode: $languageCode) {
    name
    description
  }
  minimumDeliveryDays
  maximumDeliveryDays
  price {
    ...MoneyFragment
  }
}
fragment OrderCheckoutMetadataFragment on ObjectWithMetadata {
  taxNumber: metafield(key: "taxNumber")
  shippingInfo: metafield(key: "shippingInfo")
}
fragment OrderDetailsFragment on Order {
  id
  number
  userEmail
  isShippingRequired
  collectionPointName
  displayGrossPrices
  shippingAddress {
    ...AddressFragment
  }
  billingAddress {
    ...AddressFragment
  }
  lines {
    ...OrderLineFragment
  }
  total {
    ...TaxedMoneyFragment
  }
  subtotal {
    ...TaxedMoneyFragment
  }
  deliveryMethod {
    ...DeliveryMethodFragment
  }
  voucher {
    ...VoucherFragment
  }
  fulfillments {
    ...FulfillmentFragment
  }
  paymentStatus
  ...OrderCheckoutMetadataFragment
}
fragment OrderLineFragment on OrderLine {
  id
  quantity
  productName
  variantName
  translatedProductName
  translatedVariantName
  variant {
    id
    product {
      id
      slug
    }
  }
  thumbnail(size: $thumbnailSize, format: $thumbnailFormat) {
    ...ImageFragment
  }
  unitPrice {
    ...TaxedMoneyFragment
  }
  undiscountedUnitPrice {
    ...TaxedMoneyFragment
  }
}
fragment VoucherFragment on Voucher {
  code
  currency
  discountValue
}
fragment FulfillmentFragment on Fulfillment {
  trackingNumber
}`) as unknown as TypedDocumentString<OrderDetailsQuery, OrderDetailsQueryVariables>;
export const OrderTransactionsQueryDocument = new TypedDocumentString(`
    query OrderTransactionsQuery($id: ID!) {
  order(id: $id) {
    paymentStatus
    payments {
      chargeStatus
      pspReference
      transactions {
        token
        isSuccess
        kind
      }
    }
  }
}
    `) as unknown as TypedDocumentString<OrderTransactionsQuery, OrderTransactionsQueryVariables>;
export const PageQueryDocument = new TypedDocumentString(`
    query PageQuery($slug: String!, $languageCode: LanguageCodeEnum!) {
  page(slug: $slug) {
    slug
    title
    content
    translation(languageCode: $languageCode) {
      title
      content
    }
  }
}
    `) as unknown as TypedDocumentString<PageQuery, PageQueryVariables>;
export const PagesTypeQueryDocument = new TypedDocumentString(`
    query PagesTypeQuery($first: Int!) {
  pageTypes(first: $first) {
    edges {
      node {
        id
        slug
      }
    }
  }
}
    `) as unknown as TypedDocumentString<PagesTypeQuery, PagesTypeQueryVariables>;
export const ProductAvailabilityInfoQueryDocument = new TypedDocumentString(`
    query ProductAvailabilityInfoQuery($slug: String!, $channel: String!, $countryCode: CountryCode!) {
  product(slug: $slug, channel: $channel) {
    ...ProductAvailabilityInfoFragment
  }
}
    fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment ProductAvailabilityInfoFragment on Product {
  id
  pricing {
    displayGrossPrices
    priceRange {
      start {
        ...TaxedMoneyFragment
      }
      stop {
        ...TaxedMoneyFragment
      }
    }
  }
  isAvailable(address: {country: $countryCode})
  variantsAvailability: variants {
    id
    quantityLimitPerCustomer
    quantityAvailable(countryCode: $countryCode)
    pricing {
      ...VariantPricingFragment
    }
  }
}
fragment VariantPricingFragment on VariantPricingInfo {
  onSale
  discount {
    currency
  }
  price {
    ...TaxedMoneyFragment
  }
  priceUndiscounted {
    ...TaxedMoneyFragment
  }
}`) as unknown as TypedDocumentString<ProductAvailabilityInfoQuery, ProductAvailabilityInfoQueryVariables>;
export const ProductCardsQueryDocument = new TypedDocumentString(`
    query ProductCardsQuery($channel: String!, $countryCode: CountryCode!, $filter: ProductFilterInput, $where: ProductWhereInput, $sort: ProductOrder, $after: String, $before: String, $first: Int, $last: Int, $thumbnailFormat: ThumbnailFormatEnum!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!) {
  products(
    channel: $channel
    filter: $filter
    where: $where
    sortBy: $sort
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        ...ProductCardFragment
      }
    }
  }
}
    fragment PageInfoFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  endCursor
  startCursor
}
fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment ProductMediaFragment on ProductMedia {
  alt
  type
  thumbnail: url(size: $thumbnailSize, format: $thumbnailFormat)
}
fragment ProductCardFragment on Product {
  ...ProductBasicDetailsFragment
  created
  variants {
    id
    name
  }
}
fragment ProductBasicDetailsFragment on Product {
  id
  slug
  description
  name
  translation(languageCode: $languageCode) {
    description
    name
  }
  isAvailable(address: {country: $countryCode})
  media {
    ...ProductMediaFragment
  }
  pricing {
    ...ProductPriceFragment
  }
}
fragment ProductPriceFragment on ProductPricingInfo {
  onSale
  displayGrossPrices
  discount {
    currency
  }
  priceRange {
    ...TaxedRangePriceFragment
  }
  priceRangeUndiscounted {
    ...TaxedRangePriceFragment
  }
}
fragment TaxedRangePriceFragment on TaxedMoneyRange {
  start {
    ...TaxedMoneyFragment
  }
  stop {
    ...TaxedMoneyFragment
  }
}`) as unknown as TypedDocumentString<ProductCardsQuery, ProductCardsQueryVariables>;
export const ProductCategoriesQueryDocument = new TypedDocumentString(`
    query ProductCategoriesQuery($slug: String!, $channel: String!, $languageCode: LanguageCodeEnum!) {
  product(slug: $slug, channel: $channel) {
    category {
      ...CategoryUrlWithParentsFragment
    }
  }
}
    fragment CategoryUrlWithParentsFragment on Category {
  ...CategoryUrlFragment
  translation(languageCode: $languageCode) {
    name
  }
  parent {
    ...CategoryUrlFragment
    parent {
      ...CategoryUrlFragment
      parent {
        ...CategoryUrlFragment
        parent {
          ...CategoryUrlFragment
          parent {
            ...CategoryUrlFragment
          }
        }
      }
    }
  }
}
fragment CategoryUrlFragment on Category {
  slug
  name
  translation(languageCode: $languageCode) {
    name
  }
}`) as unknown as TypedDocumentString<ProductCategoriesQuery, ProductCategoriesQueryVariables>;
export const ProductDetailsQueryDocument = new TypedDocumentString(`
    query ProductDetailsQuery($slug: String!, $channel: String!, $countryCode: CountryCode!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!, $thumbnailFormat: ThumbnailFormatEnum!, $ogThumbnailSize: Int!) {
  product(slug: $slug, channel: $channel) {
    ogThumbnail: thumbnail(format: ORIGINAL, size: $ogThumbnailSize) {
      ...ImageFragment
    }
    ...ProductDetailsFragment
  }
}
    fragment CategoryBasicDetailsFragment on Category {
  id
  name
  slug
  description
  translation(languageCode: $languageCode) {
    name
    description
  }
}
fragment ImageFragment on Image {
  alt
  url
}
fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment ProductMediaFragment on ProductMedia {
  alt
  type
  thumbnail: url(size: $thumbnailSize, format: $thumbnailFormat)
}
fragment VariantPricingFragment on VariantPricingInfo {
  onSale
  discount {
    currency
  }
  price {
    ...TaxedMoneyFragment
  }
  priceUndiscounted {
    ...TaxedMoneyFragment
  }
}
fragment ProductBasicDetailsFragment on Product {
  id
  slug
  description
  name
  translation(languageCode: $languageCode) {
    description
    name
  }
  isAvailable(address: {country: $countryCode})
  media {
    ...ProductMediaFragment
  }
  pricing {
    ...ProductPriceFragment
  }
}
fragment ProductPriceFragment on ProductPricingInfo {
  onSale
  displayGrossPrices
  discount {
    currency
  }
  priceRange {
    ...TaxedRangePriceFragment
  }
  priceRangeUndiscounted {
    ...TaxedRangePriceFragment
  }
}
fragment TaxedRangePriceFragment on TaxedMoneyRange {
  start {
    ...TaxedMoneyFragment
  }
  stop {
    ...TaxedMoneyFragment
  }
}
fragment ProductDetailsFragment on Product {
  ...ProductBasicDetailsFragment
  variants {
    weight {
      ...WeightFragment
    }
    ...ProductVariantDetailsFragment
  }
  productType {
    weight {
      ...WeightFragment
    }
  }
  weight {
    ...WeightFragment
  }
  attributes {
    ...SelectedAttributeFragment
  }
  category {
    ...CategoryBasicDetailsFragment
  }
}
fragment WeightFragment on Weight {
  value
  unit
}
fragment ProductVariantDetailsFragment on ProductVariant {
  ...ProductVariantBasicDetailsFragment
  attributes {
    ...SelectedAttributeFragment
  }
}
fragment ProductVariantBasicDetailsFragment on ProductVariant {
  id
  name
  sku
  translation(languageCode: $languageCode) {
    name
  }
  pricing {
    ...VariantPricingFragment
  }
  media {
    ...ProductMediaFragment
  }
  quantityAvailable(countryCode: $countryCode)
  quantityLimitPerCustomer
}
fragment SelectedAttributeFragment on SelectedAttribute {
  attribute {
    name
    translation(languageCode: $languageCode) {
      name
    }
    slug
    id
    inputType
    unit
  }
  values {
    ...AttributeValueFragment
  }
}
fragment AttributeValueFragment on AttributeValue {
  id
  slug
  name
  translation(languageCode: $languageCode) {
    name
    richText
    plainText
  }
  richText
  plainText
  boolean
  date
  dateTime
  value
}`) as unknown as TypedDocumentString<ProductDetailsQuery, ProductDetailsQueryVariables>;
export const ProductDetailsRawDetailsQueryDocument = new TypedDocumentString(`
    query ProductDetailsRawDetailsQuery($slug: String!, $channel: String!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!, $thumbnailFormat: ThumbnailFormatEnum!) {
  product(slug: $slug, channel: $channel) {
    id
    slug
    name
    translation(languageCode: $languageCode) {
      name
    }
    thumbnail(size: $thumbnailSize, format: $thumbnailFormat) {
      alt
      url
    }
    productType {
      name
    }
    category {
      ...CategoryBasicDetailsFragment
    }
  }
}
    fragment CategoryBasicDetailsFragment on Category {
  id
  name
  slug
  description
  translation(languageCode: $languageCode) {
    name
    description
  }
}`) as unknown as TypedDocumentString<ProductDetailsRawDetailsQuery, ProductDetailsRawDetailsQueryVariables>;
export const ProductFiltersQueryDocument = new TypedDocumentString(`
    query ProductFiltersQuery($channel: String!, $languageCode: LanguageCodeEnum!) {
  attributes(first: 100, where: {visibleInStorefront: true}) {
    edges {
      node {
        ...FilterAttributeFragment
      }
    }
  }
  maxProductsPrice: products(
    first: 1
    channel: $channel
    sortBy: {direction: DESC, field: PRICE}
  ) {
    edges {
      node {
        pricing {
          priceRange {
            stop {
              ...TaxedMoneyFragment
            }
          }
        }
      }
    }
  }
  minProductsPrice: products(
    first: 1
    channel: $channel
    sortBy: {direction: ASC, field: PRICE}
  ) {
    edges {
      node {
        pricing {
          priceRange {
            start {
              ...TaxedMoneyFragment
            }
          }
        }
      }
    }
  }
}
    fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment AttributeValueFragment on AttributeValue {
  id
  slug
  name
  translation(languageCode: $languageCode) {
    name
    richText
    plainText
  }
  richText
  plainText
  boolean
  date
  dateTime
  value
}
fragment FilterAttributeFragment on Attribute {
  ...AttributeFragment
  withChoices
  choices(first: 100, sortBy: {direction: ASC, field: NAME}) {
    edges {
      node {
        ...AttributeValueFragment
      }
    }
  }
}
fragment AttributeFragment on Attribute {
  name
  slug
  translation(languageCode: $languageCode) {
    name
  }
  inputType
  slug
}`) as unknown as TypedDocumentString<ProductFiltersQuery, ProductFiltersQueryVariables>;
export const ProductListingQueryDocument = new TypedDocumentString(`
    query ProductListingQuery($channel: String!, $countryCode: CountryCode!, $filter: ProductFilterInput, $where: ProductWhereInput, $search: String, $sort: ProductOrder, $after: String, $before: String, $first: Int, $last: Int, $thumbnailFormat: ThumbnailFormatEnum!, $languageCode: LanguageCodeEnum!, $thumbnailSize: Int!) {
  products(
    channel: $channel
    filter: $filter
    where: $where
    search: $search
    sortBy: $sort
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        created
        ...ProductBasicDetailsFragment
        variants {
          id
          name
        }
      }
    }
  }
}
    fragment PageInfoFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  endCursor
  startCursor
}
fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment ProductMediaFragment on ProductMedia {
  alt
  type
  thumbnail: url(size: $thumbnailSize, format: $thumbnailFormat)
}
fragment ProductBasicDetailsFragment on Product {
  id
  slug
  description
  name
  translation(languageCode: $languageCode) {
    description
    name
  }
  isAvailable(address: {country: $countryCode})
  media {
    ...ProductMediaFragment
  }
  pricing {
    ...ProductPriceFragment
  }
}
fragment ProductPriceFragment on ProductPricingInfo {
  onSale
  displayGrossPrices
  discount {
    currency
  }
  priceRange {
    ...TaxedRangePriceFragment
  }
  priceRangeUndiscounted {
    ...TaxedRangePriceFragment
  }
}
fragment TaxedRangePriceFragment on TaxedMoneyRange {
  start {
    ...TaxedMoneyFragment
  }
  stop {
    ...TaxedMoneyFragment
  }
}`) as unknown as TypedDocumentString<ProductListingQuery, ProductListingQueryVariables>;
export const ProductSlugQueryDocument = new TypedDocumentString(`
    query ProductSlugQuery($id: ID!) {
  product(id: $id) {
    slug
  }
}
    `) as unknown as TypedDocumentString<ProductSlugQuery, ProductSlugQueryVariables>;
export const ProductVariantCardsQueryDocument = new TypedDocumentString(`
    query ProductVariantCardsQuery($ids: [ID!], $channel: String!, $sort: ProductVariantSortingInput, $after: String, $before: String, $thumbnailFormat: ThumbnailFormatEnum!, $languageCode: LanguageCodeEnum!, $first: Int, $last: Int, $thumbnailSize: Int!, $filter: ProductVariantFilterInput, $countryCode: CountryCode!) {
  productVariants(
    ids: $ids
    channel: $channel
    filter: $filter
    sortBy: $sort
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        ...ProductVariantCardFragment
      }
    }
  }
}
    fragment PageInfoFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  endCursor
  startCursor
}
fragment TaxedMoneyFragment on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
fragment MoneyFragment on Money {
  amount
  currency
}
fragment ProductMediaFragment on ProductMedia {
  alt
  type
  thumbnail: url(size: $thumbnailSize, format: $thumbnailFormat)
}
fragment VariantPricingFragment on VariantPricingInfo {
  onSale
  discount {
    currency
  }
  price {
    ...TaxedMoneyFragment
  }
  priceUndiscounted {
    ...TaxedMoneyFragment
  }
}
fragment ProductBasicDetailsFragment on Product {
  id
  slug
  description
  name
  translation(languageCode: $languageCode) {
    description
    name
  }
  isAvailable(address: {country: $countryCode})
  media {
    ...ProductMediaFragment
  }
  pricing {
    ...ProductPriceFragment
  }
}
fragment ProductPriceFragment on ProductPricingInfo {
  onSale
  displayGrossPrices
  discount {
    currency
  }
  priceRange {
    ...TaxedRangePriceFragment
  }
  priceRangeUndiscounted {
    ...TaxedRangePriceFragment
  }
}
fragment TaxedRangePriceFragment on TaxedMoneyRange {
  start {
    ...TaxedMoneyFragment
  }
  stop {
    ...TaxedMoneyFragment
  }
}
fragment ProductVariantBasicDetailsFragment on ProductVariant {
  id
  name
  sku
  translation(languageCode: $languageCode) {
    name
  }
  pricing {
    ...VariantPricingFragment
  }
  media {
    ...ProductMediaFragment
  }
  quantityAvailable(countryCode: $countryCode)
  quantityLimitPerCustomer
}
fragment ProductVariantCardFragment on ProductVariant {
  created
  ...ProductVariantBasicDetailsFragment
  product {
    ...ProductBasicDetailsFragment
  }
}`) as unknown as TypedDocumentString<ProductVariantCardsQuery, ProductVariantCardsQueryVariables>;
export const ProductsPathQueryDocument = new TypedDocumentString(`
    query ProductsPathQuery($first: Int, $channel: String!, $after: String, $filter: ProductFilterInput) {
  products(first: $first, after: $after, channel: $channel, filter: $filter) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        slug
      }
    }
  }
}
    fragment PageInfoFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  endCursor
  startCursor
}`) as unknown as TypedDocumentString<ProductsPathQuery, ProductsPathQueryVariables>;
export const ProductsSearchDocument = new TypedDocumentString(`
    query ProductsSearch($channel: String!, $filter: ProductFilterInput, $languageCode: LanguageCodeEnum!, $limit: Int!, $search: String) {
  products(channel: $channel, filter: $filter, first: $limit, search: $search) {
    edges {
      node {
        ...ProductSearchFragment
      }
    }
  }
}
    fragment ProductSearchFragment on Product {
  slug
  name
  translation(languageCode: $languageCode) {
    name
  }
}`) as unknown as TypedDocumentString<ProductsSearch, ProductsSearchVariables>;
export const PromotionsBarItemsQueryDocument = new TypedDocumentString(`
    query PromotionsBarItemsQuery($languageCode: LanguageCodeEnum!, $pageTypeID: ID!) {
  pages(first: 25, filter: {pageTypes: [$pageTypeID]}) {
    edges {
      node {
        content
        translation(languageCode: $languageCode) {
          content
        }
        attributes {
          attribute {
            slug
          }
          values {
            dateTime
          }
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<PromotionsBarItemsQuery, PromotionsBarItemsQueryVariables>;
export const ShopQueryDocument = new TypedDocumentString(`
    query ShopQuery {
  shop {
    countries {
      ...CountryFragment
    }
  }
}
    fragment CountryFragment on CountryDisplay {
  code
}`) as unknown as TypedDocumentString<ShopQuery, ShopQueryVariables>;
export const SiteConfigQueryDocument = new TypedDocumentString(`
    query SiteConfigQuery($languageCode: LanguageCodeEnum!, $channel: String!) {
  shop {
    countries(languageCode: $languageCode) {
      ...CountryFragment
    }
    companyAddress {
      ...AddressFragment
      email: metafield(key: "email")
      nip: metafield(key: "nip")
      regon: metafield(key: "regon")
      description: metafield(key: "description")
    }
  }
  channel(slug: $channel) {
    countries {
      ...CountryFragment
    }
  }
  navigation: menu(slug: "navbar") {
    id
    name
    items {
      ...MenuItemFragment
      children {
        ...MenuItemFragment
        children {
          ...MenuItemFragment
          children {
            ...MenuItemFragment
            children {
              ...MenuItemFragment
            }
          }
        }
      }
    }
  }
  categories(first: 50, sortBy: {field: NAME, channel: $channel, direction: ASC}) {
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        slug
        name
        translation(languageCode: $languageCode) {
          name
        }
        products {
          totalCount
        }
      }
    }
  }
  footerFirst: menu(slug: "footer-first") {
    id
    name
    items {
      ...MenuItemFragment
    }
  }
  footerSecond: menu(slug: "footer-second") {
    id
    name
    items {
      ...MenuItemFragment
    }
  }
}
    fragment AddressFragment on Address {
  id
  firstName
  lastName
  companyName
  streetAddress1
  streetAddress2
  city
  postalCode
  country {
    ...CountryFragment
  }
  countryArea
  phone
  isDefaultBillingAddress
  isDefaultShippingAddress
}
fragment CountryFragment on CountryDisplay {
  code
}
fragment MenuItemFragment on MenuItem {
  id
  name
  url
  translation(languageCode: $languageCode) {
    name
  }
  category {
    id
    slug
    name
    translation(languageCode: $languageCode) {
      name
    }
  }
  collection {
    id
    slug
    name
    translation(languageCode: $languageCode) {
      name
    }
  }
  page {
    id
    slug
    title
    translation(languageCode: $languageCode) {
      title
    }
  }
}`) as unknown as TypedDocumentString<SiteConfigQuery, SiteConfigQueryVariables>;
export const TopSellingProductVariantsQueryDocument = new TypedDocumentString(`
    query TopSellingProductVariantsQuery($channel: String!, $first: Int!, $salePeriod: ReportingPeriod!) {
  reportProductSales(first: $first, channel: $channel, period: $salePeriod) {
    edges {
      node {
        id
      }
    }
  }
}
    `) as unknown as TypedDocumentString<TopSellingProductVariantsQuery, TopSellingProductVariantsQueryVariables>;